/* eslint-disable max-lines */
import { User } from "@sentry/node";
import { tggTenantClient } from "@services/AxiosConfig";
import {
  ActiveInfoDto,
  AgencyDisplayModel,
  AuditInfoDto,
  ClientDisplayModel,
  PermissionRoleGroupDisplayModel,
} from "@tgg_accounting/tenant-api";
import { QueryFunctionContext } from "react-query";

// User Data Type
export interface UserType {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  institutionName: string;
  imageUrl: string;
  subjectId: string;
  permissions: string[];
  userRoles: string[];
  userStatus: string;
  activeInfo: ActiveInfoDto;
  auditInfo: AuditInfoDto;
  archiveInfo: {
    archived: boolean;
    archivedStatusChangedAt: string;
  };
  lockInfo: {
    locked: boolean;
    lockedStatusChangedAt: string;
  };
  invitation: {
    id: string;
    invitationCode: string;
    invitationStatus: string;
    activeInfo: ActiveInfoDto;
    auditInfo: AuditInfoDto;
    lastResentAt: string;
  };
  agency: AgencyDisplayModel;
  client: ClientDisplayModel;
  assignedClients: ClientDisplayModel[];
  roleGroup: PermissionRoleGroupDisplayModel;
  assignedAgencies: AgencyDisplayModel[];
  tggUserClients: ClientDisplayModel[];
}

// Input Types
interface GetAllUsersInput {
  queryKey: [
    string,
    {
      agencyId?: string;
      include: string;
      jobRole: string;
      clientId: string;
      sortDir?: string;
      sortBy?: string;
      take: number;
      skip: number;
      searchTerm?: string;
      active?: boolean;
    }
  ];
}

interface AddUserInput {
  email: string;
  firstName: string;
  lastName: string;
  jobRole: string;
  agencyId: string;
  clientId: string;
}

interface UpdateUserByIdInput {
  state: {
    firstName: string;
    lastName: string;
    email: string;
  };
  editingUser: User[] | null;
}

interface GetUserByIdInput {
  queryKey: [string, { userId: string; include?: string }];
}

interface FindUsersInput {
  queryKey: [string, { criteria: string; mode: string }];
}

// Output Types
interface GetAllUsersOutput {
  items: UserType[];
  currentPageNumber: number;
  recordsPerPage: number;
  totalRecords: number;
  totalPages: number;
}

type AddUserOutput = UserType;
type ValidateInviteCodeOutput = UserType;
type ResendInviteOutput = { id?: string; value: boolean };
type UpdateUserByIdOutput = UserType;
type GetUserByIdOutput = UserType;
type FindUsersOutput = UserType[];

// UsersServices Class
class UsersServices {
  // GET ALL USERS
  getAllUsers = async ({
    queryKey,
  }: QueryFunctionContext<
    GetAllUsersInput["queryKey"]
  >): Promise<GetAllUsersOutput> => {
    const [
      _key,
      {
        agencyId = "",
        clientId = "",
        include = "",
        jobRole = "",
        sortDir = "ASC",
        sortBy = "",
        take = 10,
        skip = 0,
        searchTerm = "",
        active = "",
      },
    ] = queryKey;
    return await tggTenantClient.get("/users", {
      params: {
        agencyId,
        clientId,
        include,
        userRole: jobRole,
        sortDir,
        sortBy,
        take,
        skip,
        searchTerm,
        active,
      },
    });
  };

  // INVITE USER
  addUser = async (data: AddUserInput): Promise<AddUserOutput | null> => {
    if (data) {
      return await tggTenantClient.post(`/users`, data);
    }
    console.warn("data not provided, UsersServices.addUser");
    return null;
  };

  // VALIDATE INVITE CODE
  validateInviteCode = async (
    inviteCode: string
  ): Promise<ValidateInviteCodeOutput | null> => {
    if (inviteCode) {
      return await tggTenantClient.get(
        `/invitations/validate?inviteCode=${inviteCode}`
      );
    }
    console.warn("inviteCode not provided, UsersServices.validateInviteCode");
    return null;
  };

  // RESEND INVITE
  resendInvite = async (userId: string): Promise<ResendInviteOutput | null> => {
    if (userId) {
      return await tggTenantClient.post(`/users/${userId}/resend-invite`);
    }
    console.warn("userId not provided, UsersServices.resendInvite");
    return null;
  };

  // CANCEL INVITE
  cancelInvite = async (userId: string): Promise<ResendInviteOutput | null> => {
    if (userId) {
      return await tggTenantClient.post(`/users/${userId}/cancel-invite`);
    }
    console.warn("userId not provided, UsersServices.cancelInvite");
    return null;
  };

  // REJECT INVITE
  rejectInvite = async (code: string): Promise<ResendInviteOutput | null> => {
    if (code) {
      return await tggTenantClient.post(`/users/${code}/reject`);
    }
    console.warn("code not provided, UsersServices.rejectInvite");
    return null;
  };

  // UPDATE USER BY ID
  updateUserById = async (
    userId: string,
    data: UpdateUserByIdInput
  ): Promise<UpdateUserByIdOutput | null> => {
    if (userId && data) {
      return await tggTenantClient.patch(`/users/${userId}`, data);
    }
    console.warn("userId or data not provided, UsersServices.updateUserById");
    return null;
  };

  // GET USER BY ID
  getUserById = async ({
    queryKey,
  }: GetUserByIdInput): Promise<GetUserByIdOutput | null> => {
    const [_key, { userId, include = "" }] = queryKey;
    if (userId) {
      return await tggTenantClient.get(`/users/${userId}/details`, {
        params: { include },
      });
    }
    console.warn("userId not provided, UsersServices.getUserById");
    return null;
  };

  // ASSIGN PERMISSIONS TO USER
  assignPermissions = async (
    userId: string,
    data: Record<string, unknown>
  ): Promise<ResendInviteOutput | null> => {
    if (userId && data) {
      return await tggTenantClient.post(
        `/users/${userId}/assign-permissions`,
        data
      );
    }
    console.warn(
      "userId or data not provided, UsersServices.assignPermissions"
    );
    return null;
  };

  // ASSIGN PERMISSIONS TO USER BY PERSONA
  assignPermissionsByPersona = async (
    userId: string,
    data: Record<string, unknown>
  ): Promise<ResendInviteOutput | null> => {
    if (userId && data) {
      return await tggTenantClient.post(
        `/users/${userId}/assign-permissions-by-persona`,
        data
      );
    }
    console.warn(
      "userId or data not provided, UsersServices.assignPermissionsByPersona"
    );
    return null;
  };

  // ACTIVATE USER
  activateUser = async (userId: string): Promise<ResendInviteOutput | null> => {
    if (userId) {
      return await tggTenantClient.post(`/users/${userId}/activate`);
    }
    console.warn("userId not provided, UsersServices.activateUser");
    return null;
  };

  // DEACTIVATE USER
  deactivateUser = async (
    userId: string
  ): Promise<ResendInviteOutput | null> => {
    if (userId) {
      return await tggTenantClient.post(`/users/${userId}/deactivate`);
    }
    console.warn("userId not provided, UsersServices.deactivateUser");
    return null;
  };

  // LOCK USER
  lockUser = async (userId: string): Promise<ResendInviteOutput | null> => {
    if (userId) {
      return await tggTenantClient.post(`/users/${userId}/lock`);
    }
    console.warn("userId not provided, UsersServices.lockUser");
    return null;
  };

  // UNLOCK USER
  unlockUser = async (userId: string): Promise<ResendInviteOutput | null> => {
    if (userId) {
      return await tggTenantClient.post(`/users/${userId}/unlock`);
    }
    console.warn("userId not provided, UsersServices.unlockUser");
    return null;
  };

  // FIND USER BY CRITERIA
  findUsers = async ({
    queryKey,
  }: FindUsersInput): Promise<FindUsersOutput | null> => {
    const [_key, { criteria = "", mode = "" }] = queryKey;

    if (criteria && mode) {
      return await tggTenantClient.get(`/users/find`, {
        params: { criteria, mode },
      });
    }

    console.warn("criteria or mode not provided, UsersServices.findUsers");
    return null;
  };

  // CONFIRM REGISTRATION
  confirmRegistration = async (
    data: Record<string, unknown>
  ): Promise<UserType | null> => {
    if (data) {
      return await tggTenantClient.post(`/users/confirm-registration`, data);
    }

    console.warn("data not provided, UsersServices.confirmRegistration");
    return null;
  };

  // UPDATE LOGO
  addUserProfilePic = async (
    userId: string,
    file: File
  ): Promise<UserType | null> => {
    if (userId) {
      const userProfile = new FormData();
      userProfile.append("file", file);
      return await tggTenantClient.post(
        `/users/${userId}/profile-pic`,
        userProfile,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    }
    console.warn(
      "userId or file not provided, UsersServices.addUserProfilePic"
    );
    return null;
  };

  // ASSIGN CLIENT USER AS EXECUTIVE MEMBER
  assignClientUserAsExecutiveMember = async (
    clientId: string,
    data: Record<string, unknown>
  ): Promise<UserType | null> => {
    if (clientId && data) {
      return await tggTenantClient.post(
        `/client/${clientId}/assign-executive-members`,
        data
      );
    }

    console.warn(
      "clientId or data not provided, UsersServices.assignClientUserAsExecutiveMember"
    );
    return null;
  };
}

const instance = new UsersServices();

export default instance;
