// Define the interface for the route object
interface IRouteObject {
  admin: {
    agency: {
      [key: string]: string;
    };
    globalAdmin: {
      [key: string]: string;
    };
    client: {
      [key: string]: string;
    };
    user: {
      [key: string]: string;
    };
    kpis: {
      [key: string]: string;
    };
    permissions: string;
    publishingRequirements: string;
    emailTemplates: string;
  };
  client: {
    [key: string]: string | { [key: string]: string };
    kpis: {
      [key: string]: string;
    };
    dataSets: {
      [key: string]: string;
    };
  };
  invitation: {
    [key: string]: string;
  };
  common: {
    [key: string]: string;
  };
}

export const routes = {
  admin: {
    agency: {
      list: "/agency",
      addAgency: "/agency/add",
    },
    globalAdmin: {
      list: "/global-admin",
      add: "/global-admin/add",
    },
    client: {
      list: "/[agencySlug]/clients",
      addClient: "/[agencySlug]/clients/add",
      editClient: "/[agencySlug]/[clientSlug]/edit",
      addProfile: "/[agencySlug]/[clientSlug]/profile",
      editProfile: "/[agencySlug]/[clientSlug]/profile",
      details: "/[agencySlug]/[clientSlug]/details",
    },
    user: {
      list: "/[agencySlug]/hub-users",
      addTGGUser: "/[agencySlug]/hub-users/add",
      userDetail: "/[agencySlug]/hub-users/[userId]",
    },
    kpis: {
      list: "/[agencySlug]/[clientSlug]/kpis",
      kpiDetails: "/[agencySlug]/[clientSlug]/kpis/[kpiId]",
      kpiAgencyDetails: "/[agencySlug]/kpis/[kpiId]",
    },
    permissions: "/permissions",
    publishingRequirements: "/publishing-requirements",
    emailTemplates: "/email-templates",
  },
  client: {
    dashboard: "/[agencySlug]/[clientSlug]/details",
    clientUserDashboard: "/[agencySlug]/[clientSlug]/dashboard",
    landing: "/[agencySlug]/[clientSlug]/landing",
    details: "/[agencySlug]/[clientSlug]/details",
    editClient: "/[agencySlug]/[clientSlug]/edit-client",
    editProfile: "/[agencySlug]/[clientSlug]/edit-profile",
    accounts: "/[agencySlug]/[clientSlug]/accounts",
    documents: "/[agencySlug]/[clientSlug]/documents",
    folderView: "/[agencySlug]/[clientSlug]/documents/[folderId]/view",
    accountAIS: "/[agencySlug]/[clientSlug]/ais-accounts",
    aisAccountsList: "/[agencySlug]/[clientSlug]/ais-accounts/list",
    accountCOA: "/[agencySlug]/[clientSlug]/accounts/chart-of-accounts",
    qbSetup: "/[agencySlug]/[clientSlug]/qb-setup",
    qbSetupQBD: "/[agencySlug]/[clientSlug]/qb-setup/setup-qbd",
    accountMapAIS: "/[agencySlug]/[clientSlug]/ais-accounts/map-ais-accounts",
    isClassification: "/[agencySlug]/[clientSlug]/is-classification",
    reports: "/[agencySlug]/[clientSlug]/reports",
    financialReport:
      "/[agencySlug]/[clientSlug]/reports/[year]/[month]/modules",
    financialReportTasks:
      "/[agencySlug]/[clientSlug]/reports/[year]/[month]/tasks",
    financialReportView: "/[agencySlug]/[clientSlug]/reports/[year]/[month]",
    financialReportPreview:
      "/[agencySlug]/[clientSlug]/reports/[year]/[month]/[packageComponentId]/preview",
    qboCallback: "/[agencySlug]/[clientSlug]/qbo/callback",
    goals: "/[agencySlug]/[clientSlug]/goals",
    goalProgress: "/[agencySlug]/[clientSlug]/goals/[goalId]/progress",
    profitRecommendations: "/[agencySlug]/[clientSlug]/profit-recommendations",
    budgetVsActual: "/[agencySlug]/[clientSlug]/budget-vs-actual",
    dataDiagnostics: "/[agencySlug]/[clientSlug]/data-diagnostics",
    reportingTemplate: "/[agencySlug]/[clientSlug]/reporting-template",
    templateBuilder:
      "/[agencySlug]/[clientSlug]/reporting-template/[reportTemplateId]/template-builder",
    preview:
      "/[agencySlug]/[clientSlug]/reporting-template/[reportTemplateId]/template-builder/[packageComponentId]/preview",
    configure:
      "/[agencySlug]/[clientSlug]/reporting-template/[reportTemplateId]/template-builder/[packageComponentId]/configure",
    settings: "/[agencySlug]/[clientSlug]/settings/client-portal",
    reportSettings: "/[agencySlug]/[clientSlug]/settings/report",
    publishingRequirementsSettings:
      "/[agencySlug]/[clientSlug]/settings/publishing-requirements",
    kpis: {
      types: "/[agencySlug]/[clientSlug]/kpis-types",
      stdKPIsList: "/[agencySlug]/[clientSlug]/std-kpis",
      stdKPIsDetails: "/[agencySlug]/[clientSlug]/std-kpis/[kpiId]",
      customKPIsList: "/[agencySlug]/[clientSlug]/custom-kpis",
      addCustomKPI: "/[agencySlug]/[clientSlug]/custom-kpis/add",
      customKPIDetails: "/[agencySlug]/[clientSlug]/custom-kpis/[kpiId]",
    },
    dataSets: {
      list: "/[agencySlug]/[clientSlug]/data-sets",
      addEditDataSet: "/[agencySlug]/[clientSlug]/data-sets/add",
      addDataSetData:
        "/[agencySlug]/[clientSlug]/data-sets/[dataSetId]/add-data",
    },
    arap: "/ar-ap",
  },
  invitation: {
    joinNow: "/join-now/[inviteCode]",
    reject: "/join-now/[inviteCode]/reject",
  },
  common: {
    base: "/",
    login: "/login",
    profile: "/profile",
  },
} satisfies IRouteObject;

const protectedAdminRoutes = Object.keys(routes.admin.client).map((r) => ({
  path: routes.admin.client[r],
  role: [],
}));
const protectedGlobalAdminRoutes = Object.keys(routes.admin.globalAdmin).map(
  (r) => ({
    path: routes.admin.globalAdmin[r],
    role: [],
  })
);
const protectedAgencyRoutes = Object.keys(routes.admin.agency).map((r) => ({
  path: routes.admin.agency[r],
  role: [],
}));
const protectedUsersRoutes = Object.keys(routes.admin.user).map((r) => ({
  path: routes.admin.user[r],
  role: [],
}));
const protectedKPIsRoutes = Object.keys(routes.admin.kpis).map((r) => ({
  path: routes.admin.kpis[r],
  role: [],
}));
const protectedClientRoutes = Object.keys(routes.client).map((r) => ({
  path: routes.client[r],
  role: [],
}));
const protectedClientKPIsRoutes = Object.keys(routes.client.kpis).map((r) => ({
  path: routes.client.kpis[r],
  role: [],
}));
const protectedClientDataSetsRoutes = Object.keys(routes.client.dataSets).map(
  (r) => ({
    path: routes.client.dataSets[r],
    role: [],
  })
);

export const protectedRoutes = [
  ...protectedAdminRoutes,
  ...protectedUsersRoutes,
  ...protectedKPIsRoutes,
  ...protectedClientRoutes,
  ...protectedClientKPIsRoutes,
  ...protectedClientDataSetsRoutes,
  ...protectedAgencyRoutes,
  ...protectedGlobalAdminRoutes,
  { path: routes.admin.emailTemplates, role: [] },
  { path: routes.admin.publishingRequirements, role: [] },
  { path: routes.admin.permissions, role: [] },
  {
    path: routes.common.profile,
    role: [],
  },
];

export function goToDashboard(
  isClientUser: boolean,
  isMultiClient: boolean = false
): string {
  if (isClientUser && isMultiClient) {
    return routes.client.landing as string;
  }
  if (isClientUser) {
    return routes.client.clientUserDashboard as string;
  }
  return routes.admin.agency.list as string;
}

// Uniq Query Strings, should not match with routes dynamic key
export const uniqQueryStrings: { [key: string]: string } = {
  clientId: "cId",
  dataSetId: "dsId",
  showClientUsers: "showClientUsers",
  isPreEditingKpi: "isPreEditingKpi",
  isCustomizingStdKpi: "isCustomizingStdKpi",
  aisAccountType: "aisType",
  tggUserId: "tuId",
  authToken: "uat",
  financialPackageId: "financialPackageId",
  reportTemplateId: "reportTemplateId",
  accountId: "aId",
};

export function queryHelper(
  route: string,
  addQuery: boolean,
  query: string
): string {
  if (addQuery) {
    return `${route}?${query}`;
  }
  return route;
}

export const replaceAgencyAndClientSlug = (
  path: string,
  agencySlug: string,
  clientSlug?: string
) => {
  return path
    .replace("[agencySlug]", agencySlug)
    .replace("[clientSlug]", clientSlug ?? "");
};

export const replaceYearAndMonth = (
  path: string,
  year: string,
  month: string
) => {
  return path.replace("[year]", year).replace("[month]", month);
};
